import React from 'react'
import styled from 'styled-components'

import {
  Layout,
  Seo,
  HeaderInternal,
  Footer,
  Question,
  LeadCapture,
} from 'components'

import { faqContent, media } from 'resources'

export default function Faq () {
  return (
    <Layout>
      <Seo />
      <HeaderInternal
        title='Perguntas frequentes'
        imagePath='faq/faq-header-image.png'
      />
      <Main>
        <Content>
          {faqContent.map(({ title, content }, index) => (
            <Question
              key={index}
              title={`${index + 1}. ${title}`}
              content={content}
            />
          ))}
        </Content>
      </Main>
      <LeadCapture border />
      <Footer />
    </Layout>
  )
}

const Main = styled.main`
  background-color: #f7f7f7;
  display: flex;
  justify-content: center;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 80rem;
  padding: 8rem 2rem 5rem;
  width: 100%;

  ${media.lessThan('md')`
    padding-top: 4rem;
  `}
`
